/**
 * Handles the automatic display of the login modal when validation errors are present on the page.
 * Additionally, it clears both the login and registration form fields when the modal is hidden,
 * and dynamically enables/disables the registration buttons based on the Terms and Conditions checkbox state.
 *
 * @event DOMContentLoaded - Triggered when the initial HTML document has been completely loaded and parsed.
 */
document.addEventListener('DOMContentLoaded', () => {
    const loginModalElement = document.getElementById('login');
    const errors = loginModalElement.querySelectorAll('.alert');

    if (errors.length) {
        const loginModal = new Modal(loginModalElement);

        loginModalElement.addEventListener('hidden.bs.modal', () => {
            errors.forEach(error => error.remove());
        });

        clearLoginForm();
        clearRegistrationForm();

        loginModal.show();
    }

    loginModalElement.addEventListener('hidden.bs.modal', () => {
        clearLoginForm();
        clearRegistrationForm();
    });

    const termsCheckbox = document.querySelector('.tab-register__toc-checkbox');
    const registerButton = document.querySelector('.tab-register__btn-register');
    const registerGoogleButton = document.querySelector('.tab-register__btn-google');

    termsCheckbox.addEventListener('change', function () {
        registerButton.classList.toggle('disabled');
        registerGoogleButton.classList.toggle('disabled');
    });
});

/**
 * Clears the fields in the registration form (username, email, name, password, and password confirmation).
 *
 * This function targets the form with the ID `form-register` and clears all input values.
 */
function clearRegistrationForm() {
    const registrationForm = document.getElementById('form-register');
    const username = registrationForm.querySelector('[name="username"]');
    const email = registrationForm.querySelector('[name="email"]');
    const name = registrationForm.querySelector('[name="name"]');
    const password = registrationForm.querySelector('[name="password"]');
    const passwordConfirm = registrationForm.querySelector('[name="password_confirm"]');

    username.value = '';
    email.value = '';
    name.value = '';
    password.value = '';
    passwordConfirm.value = '';
}

/**
 * Clears the fields in the login form (email and password).
 *
 * This function targets the form with the ID `form-login` and clears the input values.
 */
function clearLoginForm() {
    const loginForm = document.getElementById('form-login');
    const email = loginForm.querySelector('[name="email"]');
    const password = loginForm.querySelector('[name="password"]');

    email.value = '';
    password.value = '';
}